<template>
  <footer class="bg-[url('@/assets/images/footer.png')] bg-cover bg-center text-white py-6">
    <div class="max-w-6xl mx-auto px-4 font-[Inter]">
      <div class="flex items-center justify-center mb-12">
        <div class="flex flex-col items-center">
          <img src="@/assets/images/logo.png" alt="Slumbridge Logo" class="h-16 w-auto">
          <a href="#download" class="inline-flex items-center mt-6 bg-yellow-500 hover:bg-yellow-400 text-black font-medium px-6 py-3 rounded-lg">
          <img src="@/assets/images/download-icon.png" class="w-5 h-4 mr-2"/>
            Download
          </a>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 items-center text-center gap-16">
        <!-- Server Section -->
        <div>
          <h3 class="font-black text-sm text-yellow-500 mb-6">SERVER</h3>
          <ul class="space-y-3">
            <li><a href="#play" class="hover:underline text-sm">Play</a></li>
            <li><a href="#register" class="hover:underline text-sm">Register</a></li>
            <li><a href="#store" class="hover:underline text-sm">Store</a></li>
          </ul>
        </div>

        <!-- Community Section -->
        <div>
          <h3 class="font-black text-sm text-yellow-500 mb-6">COMMUNITY</h3>
          <ul class="space-y-3">
            <li><a href="#discord" class="hover:underline text-sm">Discord</a></li>
            <li><a href="#vote" class="hover:underline text-sm">Vote</a></li>
            <li><a href="#highscores" class="hover:underline text-sm">Highscores</a></li>
          </ul>
        </div>

        <!-- Knowledge Section -->
        <div>
          <h3 class="font-black text-sm text-yellow-500 mb-6">KNOWLEDGE</h3>
          <ul class="space-y-3">
            <li><a href="#updates" class="hover:underline text-sm">Updates</a></li>
            <li><a href="#help" class="hover:underline text-sm">Help</a></li>
            <li><a href="#skills" class="hover:underline text-sm">Skills</a></li>
          </ul>
        </div>
      </div>

      <div class="text-center mt-8 border-t border-gray-700 pt-4">
        <p class="text-sm">Copyright © 2024 Slumbridge. All rights reserved.</p>
        <a href="#terms" class="text-yellow-500 hover:underline text-sm mt-2">Terms and Conditions</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
};
</script>