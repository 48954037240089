<template>
  <TopBar />
  <HomePage />
  <FooterSection />
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import HomePage from "@/components/HomePage.vue";
import FooterSection from "@/components/FooterSection.vue";

export default {
  components: {
    TopBar,
    HomePage,
    FooterSection
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import url('https://fonts.cdnfonts.com/css/pricedown');
body {
  margin: 0;
  padding: 0;
}
</style>