<template>
  <div class="bg-black border-b border-gray-700 shadow-md py-3">
    <div class="max-w-[1440px] mx-auto flex justify-between items-center px-6 rounded-full">
  
      <div class="flex items-center gap-2">
        <img src="@/assets/images/discord-icon.png" alt="Join our Discord" class="w-[21px] h-[16px]" />
        <span class="text-white text-sm font-normal">Join our Discord Community!</span>
      </div>
      <div class="flex items-center gap-2">
        <img src="@/assets/images/authentication-icon.png" alt="Login or Register" class="w-4 h-4" />
        <span class="text-white text-sm font-normal">Login or Register</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
};
</script>
