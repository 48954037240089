<template>
  <div class="homepage bg-[url('@/assets/images/header.png')] bg-cover bg-center py-2">
     <NavBar />
     <div class="text-center py-20 px-6 text-white h-[750px]">
          <!-- About Slumbridge Section -->
        <h1 class="font-pricedown mt-9 text-[45px] leading-[44px] sm:text-[80px] sm:leading-[84px] font-extrabold text-center">
           <span class="text-white">Venture into the</span> <br>
           <span class="text-yellow-400">Slumbridge World</span>
        </h1>
        <p class="font-inter mt-7 text-lg sm:text-base md:text-xl text-[#c1c1c1]">
           A groundbreaking adventure<br>
           bridging OSRS with a custom server experience, coming soon.
        </p>
        <div class="flex flex-wrap justify-center gap-4 mt-10">
           <div class="timer-box">
              <div class="timer-number">28</div>
              <div class="timer-label">DAYS</div>
           </div>
           <div class="timer-box">
              <div class="timer-number">5</div>
              <div class="timer-label">HOURS</div>
           </div>
           <div class="timer-box">
              <div class="timer-number">33</div>
              <div class="timer-label">MINUTES</div>
           </div>
           <div class="timer-box">
              <div class="timer-number">49</div>
              <div class="timer-label">SECONDS</div>
           </div>
        </div>
     </div>

    <!-- About Slumbridge Section -->
    <div class="bg-[#1a1a1a] py-20">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center lg:items-start">
        <!-- Left Section -->
        <div class="text-white lg:w-1/2 flex flex-col items-center">
          <img
            src="@/assets/images/about-slumbridge-logo.png"
            alt="About Slumbridge Logo"
            class="mb-6"
            style="width: 286px; height: 132px;"
          />
          <p class="mt-4 text-[#FFFFFF] text-[16px] leading-[24px] font-[Inter] text-center">
            Slumbridge is a custom server that truly is the first of its kind. Drawing inspiration from the incredibly
            popular (yet unreleased) GhettoScape, we seek to bridge the gap between OSRS and custom servers. The world is
            brand new and waiting to be explored, but we’ve made sure to keep the core mechanics similar enough for the
            game to still feel familiar.
          </p>
          <a href="https://discord.gg/" class="inline-flex items-center mt-6 bg-yellow-500 hover:bg-yellow-400 text-black font-semibold px-6 py-3 rounded-lg">
          <img
            src="@/assets/images/black-discord-icon.png"
            class="w-5 h-4 mr-2"
          />
            Join Discord
          </a>
        </div>

        <!-- Right Section -->
        <div class="mt-10 lg:mt-0 lg:ml-16 lg:w-1/2">
          <img
            src="@/assets/images/about-slumbridge-image.png"
            class="rounded-lg shadow-lg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NavBar from "./NavBar.vue";
  
  export default {
    components: {
      NavBar
    },
  };
</script>

<style scoped src="@/assets/styles/homepage.css"></style>