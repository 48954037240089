<template>
  <div class="w-full flex justify-center my-5 relative">
    <div class="max-w-[1440px] w-full flex items-center bg-black shadow-md rounded-full px-10 h-[88px]">
      <div class="hidden lg:flex flex-1 justify-evenly gap-8">
        <a href="#home" class="nav-item">HOME</a>
        <a href="#download" class="nav-item">DOWNLOAD</a>
        <a href="#vote" class="nav-item">VOTE</a>
      </div>

      <div class="flex lg:hidden absolute left-7">
        <button @click="toggleMenu" class="text-white text-2xl focus:outline-none">
          <span v-if="!isMenuOpen">☰</span>
          <span v-else>✖</span>
        </button>
      </div>

      <div class="flex justify-center items-center flex-none mx-auto">
        <img
          src="@/assets/images/logo.png"
          alt="Slumbridge Logo"
          class="w-[200px] h-auto object-contain"
        />
      </div>

      <div class="hidden lg:flex flex-1 justify-evenly gap-8">
        <a href="#store" class="nav-item">STORE</a>
        <a href="#highscores" class="nav-item">HIGHSCORES</a>
        <a href="#updates" class="nav-item">UPDATES</a>
      </div>
    </div>

    <div
      v-if="isMenuOpen"
      class="fixed top-0 left-0 w-full h-screen bg-black flex flex-col items-center justify-center z-50"
    >
      <a href="#home" class="nav-item-mobile" @click="toggleMenu">Home</a>
      <a href="#download" class="nav-item-mobile" @click="toggleMenu">Download</a>
      <a href="#vote" class="nav-item-mobile" @click="toggleMenu">Vote</a>
      <a href="#store" class="nav-item-mobile" @click="toggleMenu">Store</a>
      <a href="#highscores" class="nav-item-mobile" @click="toggleMenu">Highscores</a>
      <a href="#updates" class="nav-item-mobile" @click="toggleMenu">Updates</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style scoped src="@/assets/styles/navbar.css"></style>

